import React, { useState, useRef, useEffect } from 'react';
import { Languages } from 'lucide-react';

const LanguageSelector = ({ currentLanguage, onLanguageChange, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  
  // Extended list of well-supported languages by GPT-4
  const languages = [
    { code: 'en', name: 'English', native: 'English' },
    { code: 'es', name: 'Spanish', native: 'Español' },
    { code: 'fr', name: 'French', native: 'Français' },
    { code: 'de', name: 'German', native: 'Deutsch' },
    { code: 'pt', name: 'Portuguese', native: 'Português' },
    { code: 'nl', name: 'Dutch', native: 'Nederlands' },
    { code: 'ja', name: 'Japanese', native: '日本語' },
    { code: 'zh', name: 'Chinese', native: '中文' },
    { code: 'ko', name: 'Korean', native: '한국어' },
    { code: 'ar', name: 'Arabic', native: 'العربية' },
    { code: 'fa', name: 'Persian', native: 'فارسی' }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const scrollContainerStyle = {
    maxHeight: '400px',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: isDarkMode ? '#4a4a4a #252525' : '#c1c1c1 #ffffff'
  };

  return (
    <div style={{ position: 'relative' }}>
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          background: 'none',
          border: 'none',
          padding: '8px',
          cursor: 'pointer',
          color: isDarkMode ? '#E0E0E0' : '#333333',
          transition: 'color 0.3s'
        }}
        title="Language Settings"
      >
        <Languages size={24} strokeWidth={1.5} />
      </button>

      {isOpen && (
        <div 
          ref={popupRef}
          style={{
            position: 'absolute',
            right: '40px',
            top: '0',
            width: '280px',
            padding: '12px',
            backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
            border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            zIndex: 1000
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            <label 
              style={{
                display: 'block',
                marginBottom: '12px',
                fontSize: '14px',
                fontWeight: '600',
                color: isDarkMode ? '#E0E0E0' : '#333333'
              }}
            >
              Response Language
            </label>
            
            <div style={scrollContainerStyle}>
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '8px',
                padding: '1px'
              }}>
                {languages.map((language) => (
                  <button
                    key={language.code}
                    onClick={() => {
                      onLanguageChange(language.code);
                      setIsOpen(false);
                    }}
                    style={{
                      padding: '8px 12px',
                      textAlign: 'left',
                      backgroundColor: currentLanguage === language.code
                        ? (isDarkMode ? '#3d3d3d' : '#f0f0f0')
                        : 'transparent',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      color: isDarkMode ? '#E0E0E0' : '#333333',
                      fontSize: '14px',
                      transition: 'background-color 0.2s',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2px',
                      ':hover': {
                        backgroundColor: isDarkMode ? '#3d3d3d' : '#f0f0f0'
                      }
                    }}
                  >
                    <span style={{ fontWeight: '500' }}>{language.native}</span>
                    <span style={{ 
                      fontSize: '12px', 
                      opacity: 0.7 
                    }}>
                      {language.name}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          
          <p style={{
            fontSize: '12px',
            color: isDarkMode ? '#666666' : '#999999',
            margin: '8px 0 0 0'
          }}>
            Select the language for the chatbot's responses.
          </p>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;