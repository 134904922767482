import React, { useState, useRef, useEffect } from 'react';
import { Settings } from 'lucide-react';

const KLimitSetting = ({ 
  kLimit, 
  onKLimitChange, 
  isDarkMode,
  isOpen,
  onToggle
}) => {
  const [localKLimit, setLocalKLimit] = useState(kLimit.toString());
  const [error, setError] = useState('');
  const popupRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        onToggle();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onToggle]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLocalKLimit(value);
    
    const numValue = parseInt(value);
    if (isNaN(numValue) || numValue < 1 || numValue > 100) {
      setError('Please enter a number between 1 and 100.');
    } else {
      setError('');
      onKLimitChange(numValue);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <button
        ref={buttonRef}
        onClick={onToggle}
        style={{
          background: 'none',
          border: 'none',
          padding: '8px',
          cursor: 'pointer',
          color: isDarkMode ? '#E0E0E0' : '#333333',
          transition: 'color 0.3s'
        }}
        title="Search Depth Settings"
      >
        <Settings size={24} />
      </button>

      {isOpen && (
        <div 
          ref={popupRef}
          style={{
            position: 'absolute',
            right: '40px',
            top: '0',
            width: '250px',
            padding: '12px',
            backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
            border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            zIndex: 1000
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            <label 
              style={{
                display: 'block',
                marginBottom: '12px',
                fontSize: '14px',
                fontWeight: '600',
                color: isDarkMode ? '#E0E0E0' : '#333333'
              }}
            >
              Search Depth (k-limit)
            </label>
            
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <input
                type="number"
                value={localKLimit}
                onChange={handleInputChange}
                min="1"
                max="100"
                style={{
                  width: '60px',
                  padding: '6px',
                  borderRadius: '4px',
                  border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
                  backgroundColor: isDarkMode ? '#1E1E1E' : '#FFFFFF',
                  color: isDarkMode ? '#E0E0E0' : '#333333',
                  fontSize: '14px'
                }}
              />
            </div>
          </div>
          
          {error && (
            <p style={{
              color: isDarkMode ? '#FF79C6' : '#2980B9', // Pink for dark mode, blue for light mode
              fontSize: '12px',
              marginTop: '8px',
              marginBottom: '4px'
            }}>
              {error}
            </p>
          )}
          
          <p style={{
            fontSize: '12px',
            color: isDarkMode ? '#666666' : '#999999',
            margin: '8px 0 0 0'
          }}>
            Higher values will search more documents but may increase response time (range: 1-100).
          </p>
        </div>
      )}
    </div>
  );
};

export default KLimitSetting;