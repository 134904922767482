import React, { useState, useRef, useCallback, useMemo } from 'react';
import { ThumbsUp, ThumbsDown, Copy, FileText, X, Search } from 'lucide-react';

const normalizeSourceId = (id) => {
  if (!id) return null;
  const match = id.toString().match(/doc_\d+/);
  return match ? match[0] : null;
};

const ContextPopup = ({ 
  isOpen, 
  onClose, 
  context, 
  systemResponse, 
  isDarkMode, 
  handleDocumentClick, 
  idMapping,
  addToClipboard
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [matchCount, setMatchCount] = useState(0);
  const scrollRef = useRef(null);
  const [clickedIcons, setClickedIcons] = useState({});

  const handleFeedback = useCallback(async (text, feedbackType) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000'}/api/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_query: text,
          system_output: systemResponse,
          feedback: feedbackType === 'like' ? 'good' : 'bad'
        }),
      });
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  }, [systemResponse]);

  // Extract source IDs from system response to identify used text chunks
  const usedSourceIds = useMemo(() => {
    const matches = systemResponse.match(/\(Source: (doc_\d+)\)/g) || [];
    return matches.map(match => {
      const id = match.match(/doc_\d+/)[0];
      return id;
    });
  }, [systemResponse]);

  const processTextWithIcons = useCallback((text, sourceIds) => {
    const parts = text.split(/(\(Source: [^,\)]+(?:, Page: \d+)?\))/g);
    return parts.map((part, index) => {
      if (part.startsWith('(Source:')) {
        const sourceMatch = part.match(/doc_\d+/);
        const sourceId = sourceMatch ? sourceMatch[0] : null;
        return (
          <FileText
            key={`icon-${index}`}
            size={16}
            onClick={() => handleDocumentClick(sourceId, idMapping)}
            style={{
              display: 'inline-block',
              marginLeft: '4px',
              marginRight: '4px',
              cursor: 'pointer',
              color: isDarkMode ? '#FF79C6' : '#bd93f9',
              verticalAlign: 'middle'
            }}
          />
        );
      }
      return part;
    });
  }, [handleDocumentClick, idMapping, isDarkMode]);

  const highlightText = useCallback((text, sourceIds) => {
    // First replace source references with icons
    const textWithIcons = processTextWithIcons(text, sourceIds);
    
    // If there's no search term, just return the text with icons
    if (!searchTerm.trim()) {
      return textWithIcons;
    }

    // Apply search highlighting to non-icon elements
    return textWithIcons.map((element, index) => {
      if (React.isValidElement(element)) {
        return element; // Return icons unchanged
      }

      const searchParts = element.split(new RegExp(`(${searchTerm})`, 'gi'));
      return searchParts.map((part, searchIndex) => 
        part.toLowerCase() === searchTerm.toLowerCase() 
          ? <mark 
              key={`${index}-${searchIndex}`} 
              style={{
                backgroundColor: isDarkMode ? '#FF79C6' : '#bd93f9',
                color: isDarkMode ? '#1E1E1E' : '#FFFFFF',
                padding: '0.1em 0.2em',
                borderRadius: '2px'
              }}
            >
              {part}
            </mark>
          : part
      );
    });
  }, [searchTerm, isDarkMode, processTextWithIcons]);

  const paragraphs = useMemo(() => {
    if (!context) return [];
    return context.split('\n\n\n')
      .filter(p => p.trim())
      .map((paragraph, idx) => {
        const sourceMatches = paragraph.match(/doc_\d+/g) || [];
        const isUsed = sourceMatches.some(id => usedSourceIds.includes(id));
        return {
          text: paragraph,
          uniqueId: `paragraph-${idx}`,
          sourceIds: sourceMatches,
          isUsed
        };
      });
  }, [context, usedSourceIds]);

  const filteredParagraphs = useMemo(() => {
    if (!searchTerm.trim()) {
      setMatchCount(0);
      return paragraphs;
    }
    
    try {
      // Escape special characters in the search term
      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const searchRegExp = new RegExp(escapedSearchTerm, 'gi');
      
      let totalMatches = 0;
      const filtered = paragraphs.filter(paragraph => {
        const textWithoutSources = paragraph.text.replace(/\(Source: [^)]+\)/g, '');
        const matches = (textWithoutSources.match(searchRegExp) || []).length;
        totalMatches += matches;
        return matches > 0;
      });
  
      setMatchCount(totalMatches);
      return filtered;
    } catch (error) {
      console.error('RegExp error:', error);
      return paragraphs; // Return unfiltered paragraphs if there's an error
    }
  }, [paragraphs, searchTerm]);

  const MemoizedParagraph = React.memo(({
    paragraph,
    index,
    isDarkMode,
    handleFeedback,
    addToClipboard,
    highlightText
  }) => {
    const { text, sourceIds, isUsed } = paragraph;

    const handleIconClick = (iconType, action) => {
      setClickedIcons(prev => ({
        ...prev,
        [`${iconType}-${index}`]: true
      }));

      action();

      setTimeout(() => {
        setClickedIcons(prev => ({
          ...prev,
          [`${iconType}-${index}`]: false
        }));
      }, 300);
    };

    return (
      <div 
        style={{
          padding: '12px',
          marginBottom: '8px',
          borderRadius: '6px',
          backgroundColor: isUsed 
            ? (isDarkMode ? 'rgba(255, 121, 198, 0.15)' : 'rgba(189, 147, 249, 0.15)')  // Brighter highlight color
            : (isDarkMode ? '#1E1E1E' : '#F8F8F8'),  // Regular background color
          border: `1px solid ${isUsed 
            ? (isDarkMode ? '#FF79C6' : '#bd93f9')  // Pink/purple border for used chunks
            : (isDarkMode ? '#3d3d3d' : '#e1e1e1')}`,  // Regular border for unused chunks
        }}
      >
        <div>{highlightText(text, sourceIds)}</div>
        <div style={{
          display: 'flex',
          gap: '4px',
          marginTop: '8px'
        }}>
          {[
            { type: 'like', Icon: ThumbsUp, action: () => handleFeedback(text, 'like') },
            { type: 'dislike', Icon: ThumbsDown, action: () => handleFeedback(text, 'dislike') },
            { type: 'copy', Icon: Copy, action: () => addToClipboard(text) }
          ].map(({ type, Icon, action }) => (
            <button
              key={type}
              onClick={() => handleIconClick(type, action)}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '4px',
                borderRadius: '4px',
                color: clickedIcons[`${type}-${index}`] ? 'white' : (isDarkMode ? '#666666' : '#999999'),
                backgroundColor: clickedIcons[`${type}-${index}`] ? (isDarkMode ? '#3498DB' : '#2980B9') : 'transparent',
                transition: 'color 0.2s ease, transform 0.2s ease, background-color 0.3s'
              }}
              title={type.charAt(0).toUpperCase() + type.slice(1)}
            >
              <Icon size={16} />
            </button>
          ))}
        </div>
      </div>
    );
  });

  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <div style={{
        width: '70%',
        height: '85%',
        backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: `2px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`
      }}>
        <div style={{
          padding: '16px',
          borderBottom: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
          display: 'flex',
          flexDirection: 'column',
          gap: '12px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <h2 style={{
              margin: 0,
              color: isDarkMode ? '#FFFFFF' : '#000000',
              fontSize: '1.25rem',
              marginLeft: '10px'
            }}>
              Details: Most Recent Query
            </h2>
            <button
              onClick={onClose}
              style={{
                background: 'none',
                border: 'none',
                color: isDarkMode ? '#FF79C6' : '#bd93f9',
                cursor: 'pointer'
              }}
            >
              <X size={24} />
            </button>
          </div>

          <div style={{ 
            position: 'relative',
            margin: '0 7px'
          }}>
            <Search 
              style={{
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: isDarkMode ? '#666666' : '#999999'
              }}
              size={18}
            />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search in content..."
              style={{
                width: '100%',
                padding: '12px 32px 12px 34px',
                borderRadius: '6px',
                border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
                backgroundColor: isDarkMode ? '#1E1E1E' : '#F8F8F8',
                color: isDarkMode ? '#FFFFFF' : '#000000',
                fontSize: '0.95rem',
                boxSizing: 'border-box'
              }}
            />
            {searchTerm.trim() && (
              <div style={{
                position: 'absolute',
                right: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                fontSize: '0.85rem',
                color: isDarkMode ? '#FF79C6' : '#bd93f9'
              }}>
                {matchCount} {matchCount === 1 ? 'match' : 'matches'}
              </div>
            )}
          </div>
        </div>

        <div 
          ref={scrollRef}
          style={{
            flex: 1,
            overflowY: 'auto',
            padding: '16px',
            backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
            color: isDarkMode ? '#FFFFFF' : '#000000'
          }}
        >
          {filteredParagraphs.map((paragraph, index) => 
            <MemoizedParagraph
              key={paragraph.uniqueId}
              paragraph={paragraph}
              index={index}
              isDarkMode={isDarkMode}
              handleFeedback={handleFeedback}
              addToClipboard={addToClipboard}
              highlightText={highlightText}
            />
          )}
          {searchTerm.trim() && filteredParagraphs.length === 0 && (
            <div style={{
              textAlign: 'center',
              padding: '2rem',
              color: isDarkMode ? '#666666' : '#999999'
            }}>
              No matches found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContextPopup;